import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import {
  Carousel as Config,
  CarouselItemType,
  Image as SceneImage,
  Pano as ScenePano,
  Video as SceneVideo,
} from '../../../../types/carousel';
import PillGroup, {
  PillConfig,
} from '../../../../components/PillGroup/PillGroup';
import {
  Image as MediaImage,
  showImage,
  showPano,
  showVideo,
  Video as MediaVideo,
} from '../../../../stores/slices/media';
import {
  getSceneListVisible,
  setSceneListVisible,
} from '../../../../stores/slices/ui';
import { animated, useSpring, useTransition } from '@react-spring/web';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import useAnalyticsEvents from '../../../../hooks/useAnalyticsEvents/useAnalyticsEvents';
import useOnClickOutside from '../../../../hooks/useOnClickOutside/useOnClickOutside';
import useViewer from '../../../../hooks/useViewer/useViewer';
import useWindowSize from '../../../../hooks/useWindowSize/useWindowSize';
import { SCENES_ID } from '../../constants';

import { ReactComponent as Arrow } from './assets/arrow.svg';
import { ReactComponent as PanoIcon } from './assets/pano.svg';
import { ReactComponent as PhotoIcon } from './assets/photo.svg';
import { ReactComponent as VideoIcon } from './assets/video.svg';

import styles from './SceneSelector.module.scss';
import { TABS_ID } from '../../../../constants/ids';
import useTheme from '../../../../hooks/useTheme/useTheme';
import useMediaQuery from '../../../../hooks/useMediaQuery/useMediaQuery';

interface SceneSelectorProps {
  availableTabs: CarouselItemType[];
  config: Config;
}

export default function SceneSelector({
  availableTabs,
  config,
}: SceneSelectorProps) {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const [pillGroupKey, setPillGroupKey] = useState<number>(0);
  const { viewer, panoId } = useViewer();
  const isMobile = useMediaQuery('sm');

  const sceneListVisible = useAppSelector(getSceneListVisible);
  const dispatch = useAppDispatch();
  const closeOnOutsideClick = () => dispatch(setSceneListVisible(false));

  const media = useAppSelector((s) => s.media);
  const analyticsEvents = useAnalyticsEvents();

  // Mount and unmount active pill group to recalculate its dimensions on window resize
  useEffect(() => setPillGroupKey((key) => key + 1), [windowSize]);
  const pillColors = {
    default: theme.styles.carousel.tabs.icons.default.color,
    active: theme.styles.carousel.tabs.icons.active.color,
  };

  const toggleSceneList = () => {
    if (media.type === 'pano') {
      dispatch(setSceneListVisible(!sceneListVisible));
    }
  };

  const sceneListAnimation = useSpring({
    bottom: sceneListVisible ? (!isMobile ? 60 : 30) : isMobile ? 0 : 0,
    opacity: sceneListVisible ? 1 : 0,
    transform: `scaleY(${sceneListVisible ? 1 : 0.97})`,
    display: sceneListVisible ? 'block' : 'none',
  });

  const sceneSelectorAnimation = useSpring({
    opacity: media.type !== 'pano' ? 0 : 1,
  });

  const pillContainerAnimation = useSpring({
    opacity: !isMobile && sceneListVisible ? 0 : 1,
  });

  const setPano = useCallback(
    (pano: ScenePano) => {
      dispatch(showPano());
      dispatch(setSceneListVisible(false));
      if (pano) {
        if (panoId !== pano.panoid) {
          viewer?.setPano(pano.panoid, { pov: pano?.pov });
        }
      }
    },
    [dispatch, panoId, viewer]
  );

  const setImage = useCallback(
    (image: SceneImage) => {
      if (image.image === (media as MediaImage).source) {
        return;
      }

      analyticsEvents.imageGalleryView(
        image.imageId,
        image.image.url,
        image.title
      );

      dispatch(
        showImage({
          imageId: image.imageId,
          imageTitle: image.title,
          source: image.image,
        })
      );
    },
    [analyticsEvents, dispatch, media]
  );

  const setVideo = useCallback(
    (video: SceneVideo) => {
      if ((media as MediaVideo).source === video) {
        return;
      }

      analyticsEvents.videoGalleryView(video.videoId, video.title);

      dispatch(showVideo(video));
    },
    [analyticsEvents, dispatch, media]
  );

  const pills: PillConfig<CarouselItemType>[] = useMemo(
    () =>
      [
        ...(config.pano.length > 0
          ? [
              {
                key: 'pano' as CarouselItemType,
                display: <PanoIcon />,
              },
            ]
          : []),
        ...(config.image.length > 0
          ? [
              {
                key: 'image' as CarouselItemType,
                display: <PhotoIcon />,
              },
            ]
          : []),
        ...(config.video.length > 0
          ? [
              {
                key: 'video' as CarouselItemType,
                display: <VideoIcon />,
              },
            ]
          : []),
      ].filter((pill) => availableTabs.includes(pill.key)),
    [availableTabs, config]
  );

  useOnClickOutside(containerRef, closeOnOutsideClick);

  const pillsTransitions = useTransition(true, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <div ref={containerRef} className={styles.container}>
      {pills.length > 1 && (
        <animated.div
          style={pillContainerAnimation}
          className={styles.pillContainer}
          id={TABS_ID}
        >
          {pillsTransitions(
            (style, item) =>
              item && (
                <animated.div style={style}>
                  <PillGroup<CarouselItemType>
                    key={pillGroupKey}
                    colors={pillColors}
                    pills={pills}
                    value={media.type}
                    pillVariant="scene"
                    onChange={(newType) => {
                      if (newType === media.type) return;
                      switch (newType) {
                        case 'pano':
                          const [firstPano] = config.pano;
                          setPano(firstPano);
                          break;
                        case 'image':
                          const [firstImage] = config.image;
                          setImage(firstImage);
                          break;
                        case 'video':
                          const [firstVideo] = config.video;
                          setVideo(firstVideo);
                          break;
                      }
                    }}
                  />
                </animated.div>
              )
          )}
        </animated.div>
      )}
      {
        <animated.div
          style={sceneSelectorAnimation}
          className={
            isMobile ? styles.containerMobile : styles.containerDesktop
          }
          onClick={toggleSceneList}
          data-cy="scene-selector"
          id={SCENES_ID}
        >
          <div className={styles.selectedPanoTitle}>
            {config.pano.find((p) => p.panoid === panoId)?.title}
          </div>
          <Arrow
            className={sceneListVisible ? styles.arrowUp : styles.arrowDown}
          />

          <animated.ul
            style={sceneListAnimation}
            data-cy="scene-list"
            className={
              sceneListVisible ? styles.sceneListOpen : styles.sceneListClosed
            }
          >
            <SimpleBar style={{ maxHeight: '400px' }}>
              {config.pano
                .filter((p) => p.carousel)
                .map((pano) => (
                  <li
                    key={pano.panoid}
                    data-cy={'scene-list-' + pano.title}
                    className={`${styles.dropdownItem} ${
                      pano.panoid === panoId ? styles.active : ''
                    }`}
                    title={pano.title}
                    onClick={() => setPano(pano)}
                  >
                    <div className={styles.selectedPanoTitle}>{pano.title}</div>
                  </li>
                ))}
            </SimpleBar>
          </animated.ul>
        </animated.div>
      }
    </div>
  );
}
